import React from 'react';

import {Form} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {useSubscriptionLoadable} from 'modules/billing/hooks/useSubscriptionLoadable';
import {Config, DeploymentEnvironment} from 'config';

import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';
import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {BillingStatusBadge} from 'components/profile/ViewProfile/ViewProfileBilling/BillingStatusBadge';

interface IBillingInfoProps {
    profile: IProfile;
}

export const BillingInfo = ({profile}: IBillingInfoProps) => {
    const {subscription, loading, error} = useSubscriptionLoadable(profile.id);

    const stripeCustomerURL = React.useMemo(() => {
        let baseURL: string;
        if (Config.env === DeploymentEnvironment.STAGING) {
            baseURL = 'https://dashboard.stripe.com/test';
        } else {
            baseURL = 'https://dashboard.stripe.com';
        }
        return `${baseURL}/customers/${subscription?.customer.id}`;
    }, [subscription]);

    const stripeSignupURL = React.useMemo(
        () => `${Config.publicURL}/redirect/stripe-signup/${profile.id}`,
        [profile.id],
    );

    const description = React.useMemo<React.ReactNode | undefined>(() => {
        if (stripeCustomerURL) {
            return (
                <p>
                    You can manage this customer in
                    Stripe <a href={stripeCustomerURL} target="_blank" rel="noreferrer">here</a>.
                </p>
            );
        }
        return undefined;
    }, [stripeCustomerURL]);

    return (
        <FormCard title="Billing" description={description}>
            {subscription && (
                <>
                    <p>
                        <BillingStatusBadge status={subscription.status} />
                    </p>
                    <Form.Group controlId="billing-shareable-link">
                        <Form.Label className="mb-0">Shareable Link:</Form.Label>
                        {stripeCustomerURL ? (
                            <>
                                <Form.Text className="mb-2 text-muted mt-1">
                                    Share this link with the customer to take them to their payments page.
                                </Form.Text>
                                <Form.Control type="text" readOnly value={stripeSignupURL} />
                            </>
                        ) : (
                            <Form.Text className="mb-2 text-muted mt-1">
                                This customer has not been assigned a plan yet. Once you assign them a plan in
                                Stripe, a shareable link will appear here.
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group controlId="billing-shareable-link">
                        <Form.Label>JSON Data:</Form.Label>
                        <pre className="mb-0 bg-light p-3 border rounded">
                            {JSON.stringify(subscription, null, 2)}
                        </pre>
                    </Form.Group>
                </>
            )}
            {loading && (
                <LoadingSpinner />
            )}
            {error && (
                <LoadingError>
                    Error loading billing information
                </LoadingError>
            )}
        </FormCard>
    );
};
